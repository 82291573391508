import {
    DeleteOutline,
    Search
} from "@mui/icons-material";
import {
    Button,
    Grid,
    IconButton,
    InputAdornment,
    TextField,
    Tooltip
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import {
    DataGrid,
    gridClasses
} from "@mui/x-data-grid";
import {
    useEffect,
    useMemo,
    useReducer,
    useState
} from "react";
import { toast } from "react-toastify";
import DownloadExampleFile from "../../components/DownloadExampleFile";
import { i18n } from "../../translate/i18n";

// Components
import { Can } from "../../components/Can";
import ConfirmationModal from "../../components/ConfirmationModal";
import ContactGroupsModal from "../../components/ContactGroupModal";
import PaperContainer from "../../components/LayoutComponents/Paper";
import Title from "../../components/LayoutComponents/Title";
import MainContainer from "../../components/MainContainer";
import MainHeader from "../../components/MainHeader";
import MainHeaderButtonsWrapper from "../../components/MainHeaderButtonsWrapper";
import TutorialModalButton from "../../components/TutorialModalButton";
import toastError from "../../errors/toastError";

// Hooks
import ColorItem from "../../components/ColorItem";
import useAxiosPrivate from "../../hooks/useAxiosPrivate";
import { useSocketListener } from "../../hooks/useSocket/Events";
import useTenant from "../../hooks/useTenant";

const useStyles = makeStyles((theme) => ({
    actionButtons: {
        border: "1px solid",
        marginLeft: theme.spacing(1),
    },
    buttonColorError: {
        color: theme.palette.error.main,
        borderColor: theme.palette.error.main,
    },
}));

const reducer = (state, action) => {
    if (action.type === "LOAD_CONTACT_GROUPS") {
        const contactGroups = action.payload;
        const newContactGroup = [];

        contactGroups?.forEach((contactGroup) => {
            const contactGroupsIndex = state.findIndex(
                (sg) => sg.id === contactGroup.id
            );

            if (contactGroupsIndex !== -1) {
                state[contactGroupsIndex] = contactGroup;
            } else {
                newContactGroup.push(contactGroup);
            }
        });

        return [...state, ...newContactGroup];
    }

    if (action.type === "UPDATE_CONTACT_GROUP") {
        const contactGroup = action.payload;
        const contactGroupIndex = state.findIndex((c) => c.id === contactGroup.id);

        if (contactGroupIndex !== -1) {
            state[contactGroupIndex] = contactGroup;
            return [...state];
        } else {
            return [contactGroup, ...state];
        }
    }

    if (action.type === "DELETE_CONTACT_GROUP") {
        const contactGroupId = action.payload;
        const contactGroupIndex = state.findIndex((c) => c.id === +contactGroupId);
        if (contactGroupIndex !== -1) {
            state.splice(contactGroupIndex, 1);
        }
        return [...state];
    }

    if (action.type === "RESET") {
        return [];
    }
};

export function ContactGroups() {
    const classes = useStyles();
    const axiosPrivate = useAxiosPrivate();

    // Context
    const { tenantId, userTenant } = useTenant();
    const [contactGroupsData, dispatch] = useReducer(reducer, []);

    // State
    const [openModal, setOpenModal] = useState(false);
    const [loading, setLoading] = useState(false);
    const [selectedContactGroup, setSelectedContactGroup] = useState(null);
    const [confirmModalOpen, setConfirmModalOpen] = useState(false);
    const [pageNumber, setPageNumber] = useState(1);
    const [hasMore, setHasMore] = useState(false);
    const [searchParam, setSearchParam] = useState("");
    const [rows, setRows] = useState([{
        id: 0,
        name: '',
        description: '',
        color: '',
        userTenant: {},
        createdAt: '',
        updateAt: '',
    }]);

    useEffect(() => {
        dispatch({ type: "RESET" });
        setPageNumber(1);
    }, [searchParam])

    useEffect(() => {
        if (!contactGroupsData) return;

        let dataGridRows = contactGroupsData.map((c) => ({
            id: c?.id,
            name: c?.name,
            description: c?.description,
            color: c?.color,
            author: c?.userTenant?.user?.name,
            createdAt: c?.createdAt,
            updatedAt: c?.updatedAt,
        }));

        setRows(dataGridRows);

    }, [contactGroupsData, loading]);

    useEffect(() => {
        if (!tenantId) return;

        let isMounted = true;
        const controller = new AbortController();
        setLoading(true);

        const fetchContactGroups = async () => {
            if (!tenantId) return;
            try {
                const { data } = await axiosPrivate.get(`/${tenantId}/contactGroups`,
                    {params: { searchParam, pageNumber }}
            );
                isMounted && dispatch({
                    type: "LOAD_CONTACT_GROUPS",
                    payload: data.contactGroups,
                });
                isMounted && setLoading(false) && setHasMore(data.hasMore);
            } catch (err) {
                isMounted && setLoading(false);
                toastError(err, "", "Error while trying to get contact groups list info on contact groups page component.");
            }
        };
        fetchContactGroups();

        return () => {
            isMounted = false;
            controller.abort();
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [searchParam, pageNumber,tenantId]);

    const handleSearch = (event) => {
        setSearchParam(event.target.value.toLowerCase());
    };

    const loadMore = () => {
        setPageNumber((prevState) => prevState + 1);
    };

    const handleScroll = (e) => {
        if (!hasMore || loading) return;
        const { scrollTop, scrollHeight, clientHeight } = e.currentTarget;
        if (scrollHeight - (scrollTop + 100) < clientHeight) {
            loadMore();
        }
    };

    // Handle Contact Groups Changes
    const handleContactGroupChange = (data) => {
        if (data.action === "create" || data.action === "update") {
            dispatch({ type: "UPDATE_CONTACT_GROUP", payload: data.contactGroup })
        }

        if (data.action === "delete") {
            dispatch({ type: "DELETE_CONTACT_GROUP", payload: data.contactGroupId })
        }
    }
    useSocketListener(`contact-group`, (data) => handleContactGroupChange(data))

    function handleOpenModal() {
        setOpenModal(true);
    }

    const handleCloseModal = () => {
        setOpenModal(false);
    };

    const handleCloseConfirmationModal = () => {
        setConfirmModalOpen(false);
        setSelectedContactGroup(null);
    };

    const handleDeleteContactGroup = async (contactGroupId) => {
        if (!tenantId) return;
        try {
            await axiosPrivate.delete(`/${tenantId}/contactGroups/${contactGroupId}`);
            toast.success(i18n.t("translation.contactGroupsModal.toasts.deleted"));
        } catch (err) {
            toastError(err);
        }
        setSelectedContactGroup(null);
    };

    const columns = useMemo(() => [
        {
            field: "id",
            headerName: i18n.t("translation.variables.table.id"),
            flex: 0.07,
            minWidth: 30,
            maxWidth: 70,
            align: "center",
            description: i18n.t("translation.contactGroups.table.descriptions.id"),
        },
        {
            field: "name",
            headerName: i18n.t("translation.variables.table.title"),
            flex: 0.1,
            minWidth: 60,
            headerAlign: "left",
            align: "left",
            renderCell: (params) => (
                <>
                    <Tooltip title={params.row.description}>
                        <>{params.row.name}</>
                    </Tooltip>
                </>
            ),
        },
        {
            field: "color",
            headerName: i18n.t("translation.variables.table.color"),
            flex: 0.1,
            minWidth: 60,
            sortable: false,
            headerAlign: "center",
            align: "center",
            renderCell: (params) => (
                <ColorItem color={params.row.color} />
            ),
        },
        {
            field: "author",
            headerName: i18n.t("translation.variables.table.user"),
            flex: 0.1,
            minWidth: 60,
            description: i18n.t("translation.contactGroups.table.descriptions.author"),
        },
        {
            field: "description",
            headerName: i18n.t("translation.variables.table.description"),
            flex: 0.3,
            minWidth: 80,
            description: i18n.t("translation.contactGroups.table.descriptions.author"),
        },
        {
            field: "createdAt",
            headerName: i18n.t("translation.variables.table.createdAt"),
            flex: 0.1,
            minWidth: 60,
            headerAlign: "center",
            align: "center",
            renderCell: params => (
                new Date(params.row.createdAt).toLocaleString(i18n.t("translation.validation.locale"), { dateStyle: 'short', timeStyle: 'short' })
            )
        },
        {
            field: "updatedAt",
            headerName: i18n.t("translation.variables.table.lastUpdate"),
            flex: 0.1,
            minWidth: 60,
            headerAlign: "center",
            align: "center",
            renderCell: params => (
                new Date(params.row.updatedAt).toLocaleString(i18n.t("translation.validation.locale"), { dateStyle: 'short', timeStyle: 'short' })
            )
        },
        {
            field: "actions",
            headerName: i18n.t("translation.variables.table.actions"),
            flex: 0.1,
            minWidth: 60,
            sortable: false,
            headerAlign: "center",
            align: "center",
            renderCell: (params) => (
                <>
                    <Can
                        role={userTenant?.role}
                        perform="contactGroups-page:delete:group"
                        yes={() => (
                            <IconButton
                                size="small"
                                className={[classes.actionButtons, classes.buttonColorError].join(' ')}
                                onClick={(e) => {
                                    setSelectedContactGroup(params.row);
                                    setConfirmModalOpen(true);
                                }}
                            >
                                <DeleteOutline />
                            </IconButton>
                        )}
                    />
                </>
            ),
        },
    ], [userTenant?.role, classes]);

    return (
        <MainContainer>
            <ConfirmationModal
                title={`${i18n.t("translation.queues.confirmationModal.title")} ${selectedContactGroup?.name}?`}
                open={confirmModalOpen}
                onClose={handleCloseConfirmationModal}
                onConfirm={() => handleDeleteContactGroup(selectedContactGroup.id)}
            >
                {i18n.t("translation.contactGroups.confirmationModal.delete.message")}
            </ConfirmationModal>
            <ContactGroupsModal
                open={openModal}
                onClose={handleCloseModal}
            />
            <MainHeader>
                <Title>
                    {i18n.t("translation.contactGroups.title")}
                    <TutorialModalButton content="contactGroups" />
                </Title>
                <MainHeaderButtonsWrapper>
                <TextField
                    className={classes.formControl}
                    placeholder={i18n.t("translation.variables.placeholders.search")}
                    type="search"
                    value={searchParam}
                    onChange={handleSearch}
                    size="small"
                    InputProps={{
                        startAdornment: (
                            <InputAdornment position="start">
                                <Search style={{ color: "gray" }} />
                            </InputAdornment>
                        ),
                    }}
                />
                    <DownloadExampleFile />
                    <Button
                        color="primary"
                        variant="contained"
                        onClick={handleOpenModal}
                    >
                        {i18n.t("translation.contactGroups.buttons.add")}
                    </Button>
                </MainHeaderButtonsWrapper>
            </MainHeader>
            <PaperContainer>
                <Grid item sx={{ width: "100%" }}>
                    {rows && <DataGrid
                        rows={rows}
                        columns={columns}
                        onScroll={handleScroll}
                        autoHeight={true}
                        getRowHeight={() => 'auto'}
                        pageSize={20}
                        getRowId={row => row.id}
                        sx={{ [`& .${gridClasses.cell}`]: { py: 1 } }}
                        rowsPerPageOptions={[5, 10, 15, 20]}
                    />}
                </Grid>
            </PaperContainer>
        </MainContainer>
    );
}

export default ContactGroups;
