import React, { useEffect, useState } from "react";

import {
    Autocomplete,
    Box,
    CircularProgress,
    createFilterOptions,
    TextField,
    Typography
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import useAxiosPrivate from "../../hooks/useAxiosPrivate";
import useTenant from "../../hooks/useTenant";

import toastError from "../../errors/toastError";
import { i18n } from "../../translate/i18n";
import ContactGroupsModal from "../ContactGroupModal";

const useStyles = makeStyles(theme => ({
    connectInfoItem: {
        justifyContent: "space-between",
        width: "100%",
    },
    connectionInfo: {
        alignItems: "center",
        display: "flex",
        float: "right",
        marginTop: "-5px",
        marginBottom: theme.spacing(-0.5),
    },
    connectionInfoSpan: {
        opacity: "70%",
    },
    new: {
        borderTop: "1px solid #dadde9",
    },
    input: {
        // marginBottom: theme.spacing(2)
    },
}));

const filter = createFilterOptions({
    trim: true,
});

const ContactGroupSelect = ({ current, fieldId, onChange }) => {
    const classes = useStyles();
    const axiosPrivate = useAxiosPrivate();

    // Context
    const { tenantId } = useTenant();

    // State
    const [loading, setLoading] = useState(false);
    const [contactGroups, setContactGroups] = useState([]);
    const [contactGroupsModalOpen, setContactGroupsModalOpen] = useState(false);
    const [searchParam, setSearchParam] = useState("");

    const fetchContactGroups = async (isMounted) => {
        if (!tenantId) return;
        try {
            const { data } = await axiosPrivate.get(
                `${tenantId}/contactGroups`,
                {params: { searchParam }}
            );
            const filtered = data?.contactGroups?.filter((contactGroup) => contactGroup.id !== current);
            if (isMounted && filtered) {
                setContactGroups(filtered);
                setLoading(false);
            }
        } catch (err) {
            toastError(err, "", "Error while trying to get bot list info on botItems component.");
        }
    };

    useEffect(() => {
        let isMounted = true;
        const controller = new AbortController();
        setLoading(true);
        fetchContactGroups(isMounted);

        return () => {
            isMounted = false;
            controller.abort();
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [searchParam, tenantId]);

    const handleOpenContactGroupsModal = () => {
        setContactGroupsModalOpen(true)
    }

    const handleCloseContactGroupsModal = () => {
        setContactGroupsModalOpen(false)

        let isMounted = true;
        const controller = new AbortController();
        setLoading(true);
        fetchContactGroups(isMounted)

        return () => {
            isMounted = false;
            controller.abort();
        }
    }

    const handleSelectOption = (e, newValue) => {
        if (newValue?.id) {
           onChange({
            target: {
                name: fieldId,
                value: newValue.id,
            },
        });
        } else if (newValue?.name) {
            handleOpenContactGroupsModal();
        }
    };

    const renderOption = (props, option) => {
        if (option.id) {
            return (
                <>
                    <li {...props}>
                        <Box>
                            <Typography
                                variant="body1"
                                component="span"
                                sx={{ fontWeight: "bold", marginRight: 1 }}
                            >
                                ({option.id})
                            </Typography>
                            <Typography variant="body1" component="span">
                                {option.name}
                            </Typography>

                            <Typography
                                variant="body2"
                                sx={{
                                    color: "text.secondary",
                                    fontSize: "0.85rem",
                                    marginTop: "2px",
                                }}
                            >
                                {option.description}
                            </Typography>
                        </Box>
                    </li>
                </>
            );
        } else {
            return (
                <li {...props} onClick={handleOpenContactGroupsModal}>
                    <Box>
                        <Typography
                            variant="body1"
                            component="span"
                            sx={{ fontWeight: "bold", marginRight: 1 }}
                        >
                            {i18n.t("translation.newTicketModal.add")} -{" "}
                            {option.name}
                        </Typography>
                    </Box>
                </li>
            );
        }
    };

    const renderOptionLabel = (option) => {
        return `${option.name}`;
    };

    const createAddContactGroupOption = (filterOptions, params) => {
        const filtered = filter(filterOptions, params);

        if (params.inputValue !== "" && !loading && searchParam.length >= 3) {
            filtered.push({
                name: `${params.inputValue}`,
            });
        }

        return filtered;
    };

    return (<>
        <ContactGroupsModal
            open={contactGroupsModalOpen}
            onClose={handleCloseContactGroupsModal}
        />

        <Autocomplete
            options={contactGroups}
            loading={loading}
            className={classes.input}
            clearOnBlur
            autoHighlight
            freeSolo
            clearOnEscape
            getOptionLabel={renderOptionLabel}
            renderOption={renderOption}
            filterOptions={createAddContactGroupOption}
            onChange={(e, newValue) => handleSelectOption(e, newValue)}
            renderInput={(params) => (
                <TextField
                    {...params}
                    name={fieldId}
                    id={fieldId}
                    label={i18n.t("translation.contactGroupsModal.form.option.contactGroup")}
                    variant="outlined"
                    autoFocus
                    // disabled={contactId? true :false}
                    onChange={(e) => setSearchParam(e.target.value)}
                    // onKeyPress={e => {
                    //     if (loading || !selectedContact) return;
                    //     else if (e.key === "Enter") {
                    //         handleSaveTicket(selectedContact.id);
                    //     }
                    // }}
                    InputProps={{
                        ...params.InputProps,
                        endAdornment: (
                            <React.Fragment>
                                {loading ? (
                                    <CircularProgress
                                        color="inherit"
                                        size={20}
                                    />
                                ) : null}
                                {params.InputProps.endAdornment}
                            </React.Fragment>
                        )
                    }}
                />
            )}
        />
    </>)
};

export default ContactGroupSelect;
